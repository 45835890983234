import { ProductListProps } from '@components/product-list/product-list.types';
import { SiteSlug } from '@volvo-cars/market-sites';
import { getMarketConfigs, defaultMarketConfig } from 'src/markets';

export function getRetailerNavigationUrl(
  siteSlug: SiteSlug,
  productHandle?: string,
): string {
  const marketConfig = getMarketConfigs(siteSlug);
  const defaultConfig = defaultMarketConfig(siteSlug);
  if (marketConfig.linkToRetailer.containToProducts && productHandle) {
    return marketConfig.linkToRetailer.containToProducts.includes(productHandle)
      ? marketConfig.linkToRetailer.link
      : defaultConfig.linkToRetailer.link;
  }

  return marketConfig.linkToRetailer.link;
}

export function getRetailerTextContent(siteSlug: SiteSlug): string {
  const marketConfig = getMarketConfigs(siteSlug);
  return marketConfig.retailerTextContent;
}

export function getFilteredNodes(
  siteSlug: SiteSlug,
  productNodeList: ProductListProps['productNodeList'],
): ProductListProps['productNodeList'] {
  // Retrieve market configuration based on site slug
  const marketConfig = getMarketConfigs(siteSlug);

  // If productNodeList is null or undefined, return it as is
  if (!productNodeList) {
    return productNodeList;
  }

  // If there are excluded product types defined in marketConfig, filter them out
  if (
    marketConfig &&
    Array.isArray(marketConfig.excludedProductTypes) &&
    marketConfig.excludedProductTypes.length > 0
  ) {
    const filteredNodes = productNodeList.filter(
      (product) =>
        !marketConfig.excludedProductTypes.includes(product.productType),
    );

    return filteredNodes;
  }

  // If no excluded product types are defined, return the original product list
  return productNodeList;
}
